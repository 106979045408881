import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--subscriptions"
export default class extends Controller {
    static targets = [ "deviceids"]
    updateDeviceId(event) {
        let element = event.currentTarget
        if (element.value.length > 0) {
            let deviceIds = JSON.parse(this.deviceidsTarget.value)
            deviceIds[element.id] = {code: element.dataset.code, device_id: element.value}
            this.deviceidsTarget.value = JSON.stringify(deviceIds)
        }
    }
}