import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--terms"
export default class extends Controller {
    static targets = [ "termType", "productField"]
    initialize() {
        this.setTerms()
    }
    setTerms() {
        let termSpecificTypes = "eula";
        termSpecificTypes.includes(this.termTypeTarget.value) ? this.productFieldTarget.classList.remove('d-none') : this.productFieldTarget.classList.add('d-none')
    }
}