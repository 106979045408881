import "@hotwired/turbo-rails"
Turbo.session.drive = false

import * as bootstrap from "bootstrap"

import Rails from '@rails/ujs';
Rails.start();

import "./controllers"
import "./legacy/storefront/cookie-consent"

const homeLink = document.querySelector('#home-link')
homeLink.addEventListener('mouseleave', function() {
  homeLink.classList.remove('active')
})


