import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--files"
export default class extends Controller {
    initialize() {
        let dates = document.getElementsByClassName('created-at-date')
        for (let element of dates) {
            let date = new Date(element.dataset.date).toString()
            element.append(date)
        }
    }
}
