import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--rebuild"
export default class extends Controller {
    static targets = [ "buildDate"]
    initialize() {
        let date = new Date(this.buildDateTarget.dataset.date).toString()
        this.buildDateTarget.append(date)
    }
}
