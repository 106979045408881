import { Controller } from "@hotwired/stimulus"
import TomSelect      from "tom-select"

// Connects to data-controller="ts--select"
export default class extends Controller {
  static values = { url: String }
  connect() {
    let config = {
      plugins: ['remove_button'],
      createFilter: "\[.*\]\ .*",
      maxOptions: 100,
      preload: true,
      hidePlaceholder: true,
    }
    if (this.urlValue) {
      config.load = (q, callback) => this.search(q, callback)
    }
    new TomSelect(this.element, config)
  }

  search(q, callback) {
    fetch(this.urlValue + "&" + new URLSearchParams({
      search: q
    }))
        .then(
            response => {
              return response.json()
            })
        .then(
            json => {
              callback(json)
        })
        .catch(
            error => {
              callback()
        }
    )
  }

}
