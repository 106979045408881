window.scrollToEnable = function (obj, selector) {
  let object = $(obj)[0];
  if (object.scrollHeight !== 0 && object.scrollHeight - object.offsetHeight <= object.scrollTop + 1) {
    $(selector).prop('disabled', false);
    $(selector).removeClass('disabled');
    $(selector).parent().attr('title', '');
    $(selector).click(function () {
      $(obj).scrollTop(0);
    });
  } else {
    $(selector).addClass('disabled');
    $(selector).prop('disabled', true);
    $(selector).parent().attr('title', 'Please scroll to bottom to Agree');
  }
};

window.enabledIfNoScrolling = function (obj, pixels, scrollable) {
  let object = $(scrollable)[0];
  let px = parseInt(pixels);
  if (object.scrollHeight > px) {
    $(obj).addClass('disabled');
    $(obj).prop('disabled', true);
  } else {
    $(obj).removeClass('disabled');
    $(obj).prop('disabled', false);
    $(obj).children().first().removeClass('disabled');
    $(obj).children().first().prop('disabled', false);
  }
};