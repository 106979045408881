import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="country-state"
export default class extends Controller {
  static targets = ["countryCode", "stateCode", "states"]
  initialize() {
    this.countryCodeTarget.value = this.countryCodeTarget.dataset.countryCode
    this.updateStates()
  }
  updateStates() {
    fetch('/api/account/get_states/'+this.countryCodeTarget.value
    ).then(
        response => {
          return response.json()
        }
    ).then(
        success => {
          let customState = document.getElementById("custom_state_field")
          if (Object.keys(success).length) {
            this.stateCodeTarget.parentElement.classList.remove('d-none')
            if (customState)
              customState.parentElement.classList.add('d-none')
            this.stateCodeTarget.replaceChildren()
            let option = document.createElement("option")
            option.value = null
            option.text = ''
            this.stateCodeTarget.appendChild(option);
            for (const [key, value] of Object.entries(success)) {
              let option = document.createElement("option")
              option.value = key
              option.text = value.name
              this.stateCodeTarget.appendChild(option)
            }
            if (this.countryCodeTarget.value === this.countryCodeTarget.dataset.countryCode) {
              this.stateCodeTarget.value = this.countryCodeTarget.dataset.stateCode
              delete this.countryCodeTarget.dataset.countryCode
            }
          } else {
            this.stateCodeTarget.parentElement.classList.add('d-none')
            if (customState)
              customState.parentElement.classList.remove('d-none')
          }
        }
    ).catch(
        error => console.log(error)
    );
  }
}