import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lock"
export default class extends Controller {
  static targets = ['main']
  unlock() {
    this.next(this.mainTarget.parentNode, 'input:disabled,textarea:disabled,select:disabled').disabled = false
    if (this.mainTarget.closest('.field_with_errors'))
      this.next(this.mainTarget.closest('.field_with_errors')).querySelector('input:disabled,textarea:disabled,select:disabled').disabled = false
    this.mainTarget.classList.remove('bi-lock-fill')
    this.mainTarget.classList.add('bi-unlock-fill')
    this.mainTarget.title = 'This field has been unlocked.'
    this.mainTarget.style.cursor = 'default'
  }
  next (elem, selector) {

    // Get the next element
    let nextElem = elem.nextElementSibling;

    // If there's no selector, return the next element
    if (!selector) {
      return nextElem;
    }

    // Otherwise, check if the element matches the selector
    if (nextElem && nextElem.matches(selector)) {
      return nextElem;
    }

    // if it's not a match, return null
    return null;

  };
}
