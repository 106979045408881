import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--coupon-calculators"
export default class extends Controller {
    static targets = [ "couponSelect", "currencyOverrideDiv", "vendorDiv", "listedDiv", "productTypeDiv"]
    initialize() {
        this.toggleFields()
    }
    toggleFields() {
        let options = document.getElementsByClassName('coupon_option')
        for (let element of options) {
            element.classList.add('d-none')
        }
        let couponType = this.couponSelectTarget.value
        if (couponType.includes('amount')) {
            this.currencyOverrideDivTarget.classList.remove('d-none')
        }
        if (couponType.includes('vendor')) {
            this.vendorDivTarget.classList.remove('d-none')
        }
        if (couponType.includes('listed') || couponType.includes('single')) {
            this.listedDivTarget.classList.remove('d-none')
        }
        if (couponType.includes('prod_type')) {
            this.productTypeDivTarget.classList.remove('d-none')
        }
    }
}