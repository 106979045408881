import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--permissions"
export default class extends Controller {
    static targets = [ "read", "write", "language"]
    static classes = [ "bgGreen", "hoverGreen"]
    connect() {
        if (this.readTarget.checked) {
            this.readTarget.parentElement.classList.add(this.bgGreenClass)
        }
        if (this.writeTarget.checked) {
            this.writeTarget.parentElement.classList.add(this.bgGreenClass)
        }
        if (this.hasLanguageTarget) {
            if (this.readTarget.checked && this.writeTarget.checked) {
                this.languageTarget.classList.remove('d-none')
            } else {
                this.languageTarget.classList.add('d-none')
            }
        }
    }
    toggleReadChecked(event) {
        let element = event.currentTarget
        element.checked ? this.readTarget.parentElement.classList.add(this.bgGreenClass) :
            this.readTarget.parentElement.classList.remove(this.bgGreenClass)
        if (!element.checked) {
            this.writeTarget.parentElement.classList.remove(this.bgGreenClass)
            this.writeTarget.checked = false;
        }
    }
    mouseoverRead(event) {
        let element = event.currentTarget
        element.classList.add(this.hoverGreenClass)
    }
    mouseoutRead(event) {
        let element = event.currentTarget
        element.classList.remove(this.hoverGreenClass)
    }
    toggleWriteChecked(event) {
        let element = event.currentTarget
        element.checked ? this.writeTarget.parentElement.classList.add(this.bgGreenClass) :
            this.writeTarget.parentElement.classList.remove(this.bgGreenClass)
        if (element.checked) {
            this.readTarget.parentElement.classList.add(this.bgGreenClass)
            this.readTarget.checked = true
        }
    }
    mouseoverWrite(event) {
        let element = event.currentTarget
        element.classList.add(this.hoverGreenClass)
        this.readTarget.parentElement.classList.add(this.hoverGreenClass)
    }
    mouseoutWrite(event) {
        let element = event.currentTarget
        element.classList.remove(this.hoverGreenClass)
        this.readTarget.parentElement.classList.remove(this.hoverGreenClass)
    }
    toggleLanguage(event) {
        let element = event.currentTarget
        if (element.checked) {
            this.readTarget.parentElement.classList.add(this.bgGreenClass)
            this.writeTarget.parentElement.classList.add(this.bgGreenClass)
            this.readTarget.checked = true
            this.writeTarget.checked = true
            this.languageTarget.classList.remove('d-none')
        } else {
            this.readTarget.parentElement.classList.remove(this.bgGreenClass)
            this.writeTarget.parentElement.classList.remove(this.bgGreenClass)
            this.readTarget.checked = false
            this.writeTarget.checked = false
            this.languageTarget.classList.add('d-none')
        }
    }
    mouseoverLanguage() {
        this.readTarget.parentElement.classList.add(this.hoverGreenClass)
        this.writeTarget.parentElement.classList.add(this.hoverGreenClass)
    }
    mouseoutLanguage() {
        this.readTarget.parentElement.classList.remove(this.hoverGreenClass)
        this.writeTarget.parentElement.classList.remove(this.hoverGreenClass)
    }
}
