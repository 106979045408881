import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="admin--products"
export default class extends Controller {
  static targets = [ "tabLink", "productType", "lakemaster", "downloadUrl", "layerIconLink", "baseMap", "thirdParty", "lakemasterRegion" ]
  static values = {
    tabName: String,
  }
  connect() {
    this.openTabFromHash();
    this.refreshFields();
  }

  openTabFromHash(){
    let hash = window.location.hash;
    if (hash)
      this.tabNameValue = hash;
    else
      this.tabNameValue = "#basics";
    for (const idx in this.tabLinkTargets)
    {
      if (this.tabLinkTargets[idx].hash == this.tabNameValue)
      {
        let tabTrigger = new bootstrap.Tab(this.tabLinkTargets[idx]);
        tabTrigger.show();
      }
    }
  }
  setHash(event){
    // Add hash when tab is clicked
    window.location.hash = event.currentTarget.hash;
  }

  showFields(productTypeTargets) {
    for(let productTypeTarget of productTypeTargets) {
      productTypeTarget.classList.remove('d-none');
    }
  }
  hideFields(productTypeTargets) {
    for(let productTypeTarget of productTypeTargets) {
      productTypeTarget.classList.add('d-none');
    }
  }

  refreshFields() {
    let currentProductType = this.productTypeTarget.value;
    switch(currentProductType) {
      case 'autochart_pc':
        this.showFields([this.downloadUrlTarget]);
        this.hideFields([this.lakemasterTarget, this.layerIconLinkTarget, this.baseMapTarget, this.thirdPartyTarget, this.lakemasterRegionTarget])
        break;
      case 'smart_strike':
        this.showFields([this.baseMapTarget, this.layerIconLinkTarget, this.lakemasterTarget]);
        this.hideFields([this.downloadUrlTarget, this.thirdPartyTarget, this.lakemasterRegionTarget]);
        break;
      case 'third_party':
        this.showFields([this.thirdPartyTarget]);
        this.hideFields([this.downloadUrlTarget, this.lakemasterTarget, this.layerIconLinkTarget, this.baseMapTarget, this.lakemasterRegionTarget])
        break;
      case 'lake_master':
        this.showFields([this.layerIconLinkTarget])
        this.hideFields([this.downloadUrlTarget, this.lakemasterTarget, this.baseMapTarget, this.thirdPartyTarget, this.lakemasterRegionTarget])
        break;
      case 'lake_master_layer':
        this.showFields([this.baseMapTarget])
        this.hideFields([this.downloadUrlTarget, this.lakemasterTarget, this.layerIconLinkTarget, this.thirdPartyTarget, this.lakemasterRegionTarget])
        break;
      case 'lake_master_region':
        this.showFields([this.lakemasterRegionTarget, this.layerIconLinkTarget]);
        this.hideFields([this.downloadUrlTarget, this.lakemasterTarget, this.baseMapTarget, this.thirdPartyTarget])
        break;
      default:
        this.hideFields([this.downloadUrlTarget, this.lakemasterTarget, this.layerIconLinkTarget, this.baseMapTarget, this.thirdPartyTarget, this.lakemasterRegionTarget])
    }
  }



}
