$(document).ready(function() {
  let cookie = require('cookie');
  let cookies = cookie.parse(document.cookie);

  $(window).on('load', function () {
    let cookieFooter = document.getElementById('cookie-footer')
    if (cookies['COOKIE-CONSENT'] !== 'acknowledged'){
      fadeIn(cookieFooter)
      cookieFooter.classList.remove('d-none')
      document.getElementById('cookie-ack').addEventListener('click', function() {
        cookieFooter.classList.add('d-none')
        cookieFooter.remove()
        document.cookie = cookie.serialize('COOKIE-CONSENT', 'acknowledged')
      })
    } else {
      cookieFooter.remove()
    }
  })
})

function fadeIn(element, duration = 600) {
  element.style.display = '';
  element.style.opacity = 0;
  var last = +new Date();
  var tick = function() {
    element.style.opacity = +element.style.opacity + (new Date() - last) / duration;
    last = +new Date();
    if (+element.style.opacity < 0.9) {
      (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
    }
  };
  tick();
}