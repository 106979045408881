import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--geofences"
export default class extends Controller {
    static targets = [ "map"]
    geofenceMap = null
    initialize() {
        this.initializeMap();
    }
    initializeMap() {
        if (typeof google === 'undefined' || this.geofenceMap) return

        let geoJsonData = null
        try {
            geoJsonData = JSON.parse(window.atob(this.mapTarget.dataset.coverage))
        } catch (e) {
            geoJsonData = this.mapTarget.dataset.coverage
        }
        if (!geoJsonData) return
        let mapOptions = {
            draggable: true,
            fullscreenControl: false,
            gestureHandling: 'greedy',
            keyboardShortcuts: false,
            mapTypeControl: false,
            mapTypeId: google.maps.MapTypeId.HYBRID,
            panControl: true,
            rotateControl: false,
            scaleControl: true,
            scrollwheel: true,
            streetViewControl: false,
            tilt: 0,
            zoomControl: true,
            controlSize: 28
        }

        this.geofenceMap = new google.maps.Map(document.getElementById('geofence-map'), mapOptions)
        let geofenceCoverage = new google.maps.Data()
        geofenceCoverage.addGeoJson(geoJsonData)
        geofenceCoverage.setMap(this.geofenceMap)

        let bounds = new google.maps.LatLngBounds()
        geofenceCoverage.forEach(function (feature) {
            extendBounds(feature.getGeometry(), bounds.extend, bounds)
        })

        function extendBounds (geometry, callback, thisArg) {
            if (geometry instanceof google.maps.LatLng) {
                callback.call(thisArg, geometry)
            } else if (geometry instanceof google.maps.Data.Point) {
                callback.call(thisArg, geometry.get())
            } else {
                geometry.getArray().forEach(function (g) {
                    extendBounds(g, callback, thisArg)
                })
            }
        }

        this.geofenceMap.fitBounds(bounds)
    }
}