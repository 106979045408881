import {Controller} from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"
import '../shared/scroll_functions'

// Connects to data-controller="registrations"
export default class extends Controller {
  static targets = [ "modalContentPlaceholder", "modalContentMain", "headerText",
    "modalBody", "termsModal", "agreedChkBox" ]

  connect() {
    this.modal = new bootstrap.Modal(this.termsModalTarget)
    this.agreedChkBoxTarget.checked = false
    this.agreedChkBoxTarget.value = 0
    this.fetched = false
    this.currentIdx = 0
  }

  showTermsOfUse(event){
    if (!this.fetched)
      this.getTermsOfUse()
    console.log(this.checkAgreed())
    this.modal.show()
  }
  getTermsOfUse() {
    fetch('/api/account/get_terms_of_use.json'
    ).then(
        response => {
          return response.json()
        }
    ).then(
        success => {
          this.term = JSON.parse(JSON.stringify(success))
          this.modalContentPlaceholderTarget.classList.add('d-none')
          this.modalContentMainTarget.classList.remove('d-none')
          this.bodyHtml = ''
          this.showSubTerm(0)
          this.fetched = true
        }
    ).catch(
        error => console.error(error)
    );
  }

  agree(event) {
    this.currentIdx += 1
    if (this.checkAgreed())
    {
      this.modal.hide()
      this.showSubTerm(0)
      this.currentIdx = 0;
    } else {
      this.showSubTerm(this.currentIdx)
    }
  }

  checkAgreed(){
    if (!this.term || this.currentIdx < this.term.sub_terms.length){
      this.agreedChkBoxTarget.checked = false
      this.agreedChkBoxTarget.value = 0
      return false
    } else {
      this.agreedChkBoxTarget.checked = true
      this.agreedChkBoxTarget.value = 1
      return true
    }
  }

  showSubTerm(index){
    this.modalBodyTarget.innerHTML =
        decodeURIComponent(escape(window.atob(this.term.sub_terms[index].content)))
    this.headerTextTarget.innerHTML = this.term.sub_terms[index].name
  }

  disagree(event){
    this.agreedChkBoxTarget.checked = false
    this.agreedChkBoxTarget.value = 0
  }

  checkHeight(event) {
    enabledIfNoScrolling(event.currentTarget, 400, '.fixed-modal-body')
  }

}
